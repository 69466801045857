import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data }) => {
	const post = data.markdownRemark
	const { previous, next } = data

	const featured = getImage(post.frontmatter.featured)
	const title = post.frontmatter.title
	const lead = post.frontmatter.description
	const readingTime = post.fields.readingTime.text
	const date = post.frontmatter.date

	return (
		<Layout>
			<Seo title={post.frontmatter.seo_title} />

			<div className="py-16 bg-white overflow-hidden">
			{/* <div className="relative py-16 bg-white overflow-hidden"> */}

				<div className="relative px-4 sm:px-6 lg:px-8">

					<div className="text-lg max-w-prose mx-auto">

						<Link to='/blog/' className="inline-block px-6 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out mb-4">
							← Назад
						</Link>

						<div className="aspect-w-19 aspect-h-9 mb-10">
							<GatsbyImage
								className="rounded-lg shadow-lg object-cover object-center h-80 w-full"
								image={featured}
								alt={title}
							/>
						</div>

						<h1>
							<span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
								{title}
							</span>
						</h1>

						<div className="flex space-x-1 text-sm text-gray-500 justify-center mt-4">
							<time dateTime={date}>{date}</time>
							<span aria-hidden="true">&middot;</span>
							<span>{readingTime}</span>
						</div>

						<p className="mt-8 text-xl text-gray-500 leading-8">
							{lead}
						</p>
					</div>
					<div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto" dangerouslySetInnerHTML={{ __html: post.html }}></div>

					<div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
						<Link to='/blog/' className="inline-block px-6 py-2.5 bg-transparent text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-gray-100 focus:text-blue-700 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 active:text-blue-800 transition duration-300 ease-in-out mb-4" style={{ textDecoration: 'none' }}>← Назад</Link>
					</div>

					{/* Pagination Previous Next */}
					{/* <nav className="blog-post-nav mt-6 prose prose-lg text-gray-500 mx-auto">
						<ul
							style={{
								display: `flex`,
								flexWrap: `wrap`,
								justifyContent: `space-between`,
								listStyle: `none`,
								padding: 0,
							}}
						>
							<li>
								{previous && (
									<Link to={previous.fields.slug} rel="prev" className="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out text-blue-600 hover:text-blue-700">
										← {previous.frontmatter.title}
									</Link>
								)}
							</li>
							<li>
								{next && (
									<Link to={next.fields.slug} rel="next" className="underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out text-blue-600 hover:text-blue-700">
										{next.frontmatter.title} →
									</Link>
								)}
							</li>
						</ul>
					</nav> */}

				</div>

			</div>

		</Layout>
	)

}

export default BlogPostTemplate

export const pageQuery = graphql`
	query BlogPostBySlug(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(id: { eq: $id }) {
			id
			fields {
				readingTime {
					text
				}
			}
			excerpt(pruneLength: 160)
			html
			frontmatter {
				title
				date(formatString: "DD MMMM, YYYY", locale: "ru-RU")
				description
				seo_description
				seo_title
				featured {
					childImageSharp {
						gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
					}
				}
			}
		}
		previous: markdownRemark(id: { eq: $previousPostId }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
		next: markdownRemark(id: { eq: $nextPostId }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
	}
`